export const MiRoutes = {
	login: {
		path: 'login',
		url: '/login',
		preauth: {
			path: 'preauth',
			url: '/login/preauth'
		}
	},
	dashboard: {
		path: 'dashboard',
		url: '/dashboard'
	},
	quotes: {
		path: 'quotes',
		url: '/quotes'
	},
	helpCenter: {
		path: 'help-center',
		url: '/help-center'
	},
	admin: {
		path: 'admin',
		url: '/admin'
	},
	noPermissions: {
		path: 'forbidden',
		url: '/forbidden'
	}
};

export const MiHomeRoute = MiRoutes.dashboard.url;
